import mutations from './mutations'

const state = {
  channel: ''
}

export default {
  namespaced: true,
  state,
  mutations
}
